import { SVGProps } from "react";

import AlertTriangle from "./_icons/alert-triangle.svg?component"
import ArrowLeft from "./_icons/arrow-left.svg?component"
import ArrowMoveDown from "./_icons/arrow-move-down.svg?component"
import ArrowMoveUp from "./_icons/arrow-move-up.svg?component"
import ArrowRight from "./_icons/arrow-right.svg?component"
import ArrowsSort from "./_icons/arrows-sort.svg?component"
import CaretDownFilled from "./_icons/caret-down-filled.svg?component"
import CaretUpFilled from "./_icons/caret-up-filled.svg?component"
import ChevronDown from "./_icons/chevron-down.svg?component"
import ChevronsRight from "./_icons/chevrons-right.svg?component"
import CircleDashedCheck from "./_icons/circle-dashed-check.svg?component"
import CircleDashedX from "./_icons/circle-dashed-x.svg?component"
import Circle from "./_icons/circle.svg?component"
import Copy from "./_icons/copy.svg?component"
import DotsVertical from "./_icons/dots-vertical.svg?component"
import Download from "./_icons/download.svg?component"
import Edit from "./_icons/edit.svg?component"
import EyeCheck from "./_icons/eye-check.svg?component"
import FilterOff from "./_icons/filter-off.svg?component"
import Filter from "./_icons/filter.svg?component"
import InfoCircleFilled from "./_icons/info-circle-filled.svg?component"
import InfoSquareRounded from "./_icons/info-square-rounded.svg?component"
import Link from "./_icons/link.svg?component"
import MapPinFilled from "./_icons/map-pin-filled.svg?component"
import Map from "./_icons/map.svg?component"
import MathSymbols from "./_icons/math-symbols.svg?component"
import Menu from "./_icons/menu.svg?component"
import Plus from "./_icons/plus.svg?component"
import RefreshDot from "./_icons/refresh-dot.svg?component"
import SortAscending from "./_icons/sort-ascending.svg?component"
import SortDescending from "./_icons/sort-descending.svg?component"
import SquareRoundedMinus from "./_icons/square-rounded-minus.svg?component"
import TopologyRing2 from "./_icons/topology-ring-2.svg?component"
import Trash from "./_icons/trash.svg?component"
import Unlink from "./_icons/unlink.svg?component"
import Upload from "./_icons/upload.svg?component"
import X from "./_icons/x.svg?component"

export type IconType =
  | "AlertTriangle"
  | "ArrowLeft"
  | "ArrowMoveDown"
  | "ArrowMoveUp"
  | "ArrowRight"
  | "ArrowsSort"
  | "CaretDownFilled"
  | "CaretUpFilled"
  | "ChevronDown"
  | "ChevronsRight"
  | "CircleDashedCheck"
  | "CircleDashedX"
  | "Circle"
  | "Copy"
  | "DotsVertical"
  | "Download"
  | "Edit"
  | "EyeCheck"
  | "FilterOff"
  | "Filter"
  | "InfoCircleFilled"
  | "InfoSquareRounded"
  | "Link"
  | "MapPinFilled"
  | "Map"
  | "MathSymbols"
  | "Menu"
  | "Plus"
  | "RefreshDot"
  | "SortAscending"
  | "SortDescending"
  | "SquareRoundedMinus"
  | "TopologyRing2"
  | "Trash"
  | "Unlink"
  | "Upload"
  | "X";

export const getIconType = (icon: string): IconType | null => {
  switch (icon.toLowerCase()) {
    case "alerttriangle":
      return "AlertTriangle";

    case "arrowleft":
      return "ArrowLeft";

    case "arrowmovedown":
      return "ArrowMoveDown";

    case "arrowmoveup":
      return "ArrowMoveUp";

    case "arrowright":
      return "ArrowRight";

    case "arrowssort":
      return "ArrowsSort";

    case "caretdownfilled":
      return "CaretDownFilled";

    case "caretupfilled":
      return "CaretUpFilled";

    case "chevrondown":
      return "ChevronDown";

    case "chevronsright":
      return "ChevronsRight";

    case "circledashedcheck":
      return "CircleDashedCheck";

    case "circledashedx":
      return "CircleDashedX";

    case "circle":
      return "Circle";

    case "copy":
      return "Copy";

    case "dotsvertical":
      return "DotsVertical";

    case "download":
      return "Download";

    case "edit":
      return "Edit";

    case "eyecheck":
      return "EyeCheck";

    case "filteroff":
      return "FilterOff";

    case "filter":
      return "Filter";

    case "infocirclefilled":
      return "InfoCircleFilled";

    case "infosquarerounded":
      return "InfoSquareRounded";

    case "link":
      return "Link";

    case "mappinfilled":
      return "MapPinFilled";

    case "map":
      return "Map";

    case "mathsymbols":
      return "MathSymbols";

    case "menu":
      return "Menu";

    case "plus":
      return "Plus";

    case "refreshdot":
      return "RefreshDot";

    case "sortascending":
      return "SortAscending";

    case "sortdescending":
      return "SortDescending";

    case "squareroundedminus":
      return "SquareRoundedMinus";

    case "topologyring2":
      return "TopologyRing2";

    case "trash":
      return "Trash";

    case "unlink":
      return "Unlink";

    case "upload":
      return "Upload";

    case "x":
      return "X";

    default:
      return null;
  }
};

const getIconAsset = (icon: IconType | string) => {
  switch (icon) {
    case "AlertTriangle":
      return AlertTriangle;

    case "ArrowLeft":
      return ArrowLeft;

    case "ArrowMoveDown":
      return ArrowMoveDown;

    case "ArrowMoveUp":
      return ArrowMoveUp;

    case "ArrowRight":
      return ArrowRight;

    case "ArrowsSort":
      return ArrowsSort;

    case "CaretDownFilled":
      return CaretDownFilled;

    case "CaretUpFilled":
      return CaretUpFilled;

    case "ChevronDown":
      return ChevronDown;

    case "ChevronsRight":
      return ChevronsRight;

    case "CircleDashedCheck":
      return CircleDashedCheck;

    case "CircleDashedX":
      return CircleDashedX;

    case "Circle":
      return Circle;

    case "Copy":
      return Copy;

    case "DotsVertical":
      return DotsVertical;

    case "Download":
      return Download;

    case "Edit":
      return Edit;

    case "EyeCheck":
      return EyeCheck;

    case "FilterOff":
      return FilterOff;

    case "Filter":
      return Filter;

    case "InfoCircleFilled":
      return InfoCircleFilled;

    case "InfoSquareRounded":
      return InfoSquareRounded;

    case "Link":
      return Link;

    case "MapPinFilled":
      return MapPinFilled;

    case "Map":
      return Map;

    case "MathSymbols":
      return MathSymbols;

    case "Menu":
      return Menu;

    case "Plus":
      return Plus;

    case "RefreshDot":
      return RefreshDot;

    case "SortAscending":
      return SortAscending;

    case "SortDescending":
      return SortDescending;

    case "SquareRoundedMinus":
      return SquareRoundedMinus;

    case "TopologyRing2":
      return TopologyRing2;

    case "Trash":
      return Trash;

    case "Unlink":
      return Unlink;

    case "Upload":
      return Upload;

    case "X":
      return X;

    default:
      throw new Error(`Icon ${icon} not found`);
  }
};

export interface IconProps extends SVGProps<SVGElement> {
  icon: IconType;
  alt?: string;
  title?: string;
}

export default function Icon({ icon, ...props }: IconProps) {
  const Asset = getIconAsset(icon);

  return <Asset {...props} />;
}
