import * as z from "zod";
import { generateTransformSchema } from "./zod-transform";

export interface SelectOption {
  label: string;
  value: string;
}

export interface Commodity {
  commodity: string;
  stowageFactorCBFT: number;
}

export interface Port {
  unlocode: string;
  displayName: string;
}

export interface Term {
  code: string;
  description: string;
  timeFactor: number;
}

export enum CalculatorModeString {
  Simple = "simple",
  Advanced = "advanced",
}

export const EmptyStringToUndefined = (arg: any) => {
  if ((typeof arg === "string" && arg === "") || arg === null) {
    return undefined;
  }

  return arg;
};

export const NumberPreprocessor = (arg: any) => {
  const res = EmptyStringToUndefined(arg);

  if (res === undefined) {
    return res;
  }

  if (typeof res === "string" && res.endsWith(".")) {
    if (res.indexOf(".") !== res.lastIndexOf(".")) {
      return res;
    }

    return parseFloat(res + "0");
  }

  return parseFloat(res);
};

export const PortInputFormSchema = z.object({
  port: z.preprocess(
    EmptyStringToUndefined,
    z.string({
      required_error: "Port is required",
      invalid_type_error: "Port is required",
    })
  ),

  cadence: z.preprocess(NumberPreprocessor, z.number().min(1).optional()),
  terms: z.preprocess(EmptyStringToUndefined, z.string().optional()),
  draft: z.preprocess(NumberPreprocessor, z.number().min(0.1).optional()),
  portDA: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
});

const SimpleCalculatorFormSchemaBase = z.object({
  mode: z
    .enum([CalculatorModeString.Simple, CalculatorModeString.Advanced])
    .default(CalculatorModeString.Simple),

  commodity: z.preprocess(
    EmptyStringToUndefined,
    z.string({
      required_error: "Commodity is required",
      invalid_type_error: "Commodity is required",
    })
  ),

  stowageFactorCBFT: z.preprocess(NumberPreprocessor, z.number().optional()),

  quantity: z.preprocess(NumberPreprocessor, z.number().min(1).optional()),

  // Tolerance is only allowed when quantity is provided
  tolerance: z.preprocess(
    NumberPreprocessor,
    z.number().min(0).max(99).optional()
  ),

  startPort: PortInputFormSchema,
  endPort: PortInputFormSchema,

  // These are shown in the advanced mode
  hire: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
  sfoPrice: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
  mgoPrice: z.preprocess(NumberPreprocessor, z.number().min(0).optional()),
});

export const SimpleCalculatorFormSchema = SimpleCalculatorFormSchemaBase.refine(
  (data) => {
    if (!data.quantity && data.tolerance !== undefined) {
      return false;
    }

    return true;
  },
  {
    message: "Tolerance is only allowed when quantity is provided",
    path: ["tolerance"],
  }
).refine(
  (data) => {
    if (data.startPort.port && data.startPort.port === data.endPort.port) {
      return false;
    }

    return true;
  },
  {
    message: "Load port and discharge port cannot be the same",
    path: ["endPort", "port"],
  }
);

export type SimpleCalculatorFormData = z.infer<
  typeof SimpleCalculatorFormSchema
>;
